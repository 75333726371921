<template>
  <v-simple-table>
    <template #default>
      <thead>
        <tr class="amber lighten-4">
          <th class="text-left">
            {{ $t('dayOfWeek') }}
          </th>
          <!--          <th class="text-left">-->
          <!--            天气-->
          <!--          </th>-->
          <th class="text-left">
            {{ $t('sales') }}
          </th>
          <th class="text-left">
            {{ $t('TotalOrders') }}
          </th>
          <th class="text-left">
            {{ $t('NumberOfPeopleDining') }}
          </th>
          <th class="text-left">
            {{ $t('Costs') }}
          </th>

          <th class="text-left">
            {{ $t('rateOfTurnTable') }}
          </th>
          <th class="text-left">
            {{ $t('NetProfit') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in dailyInfo"
          :key="item.date"
        >
          <td class="grey lighten-4">
            {{ item.date }}
          </td>
          <!--          <td>-->
          <!--            <v-icon color="black">-->
          <!--              {{ item.weather }}-->
          <!--            </v-icon>-->
          <!--          </td>-->
          <td>{{ item.orderInfo.totalTotalPrice| reportPriceDisplay }}</td>
          <td>{{ item.orderInfo.totalOrderCount| reportPriceDisplay }}</td>
          <td>{{ item.orderInfo.totalPersonCount| reportPriceDisplay }}</td>
          <td>{{ item.orderInfo.dailyCost + avgDailyFixedCost| reportPriceDisplay }}</td>
          <td>{{ item.orderInfo.dailyTurnoverRate| reportPriceDisplay }}</td>
          <td>
            <div
              :class="item.orderInfo.totalTotalPrice - (item.orderInfo.dailyCost + avgDailyFixedCost)>0?'green--text':'red--text'"
              class="text--darken-3"
            >
              {{ item.orderInfo.totalTotalPrice - (item.orderInfo.dailyCost + avgDailyFixedCost) | reportPriceDisplay }}
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { random } from 'lodash-es'
import dayjs from 'dayjs'
import { pickOneFromArray } from '@/model/utils/utils'

export default {
  name: 'EveryDayTable',
  props: {
    fixedTotalCost: {},
    totalCost: {},
    info: {}
  },
  data: function () {
    return {
      data: []
    }
  },
  computed: {
    avgDailyFixedCost () {
      return this.fixedTotalCost / dayjs().daysInMonth()
    },
    dailyInfo () {
      this.info.dailyBusinessInfo.forEach(it => {
        it.orderInfo.dailyCost = (this.totalCost / this.info.orderInfoInMonth.totalTotalPrice) * it.orderInfo.totalTotalPrice
      })
      return this.info.dailyBusinessInfo
    },
    dailyCost () {
      return (this.totalCost / dayjs().daysInMonth()).toFixed(2)
    }
  },
  mounted () {
    const weather = [
      'mdi-weather-cloudy',
      'mdi-weather-sunny',
      'mdi-weather-hail',
      'mdi-weather-pouring',
      'mdi-weather-snowy'
    ]
    this.data = [...Array(30).keys()].map(it => {
      const getANumber = (lower = 0) => {
        return random(lower, 10999.1).toFixed(2)
      }

      return {
        date: dayjs().set('day', it + 1).format('MM-DD'),
        sales: getANumber(),
        orderCount: random(0, 300),
        guestCount: random(0, 300),
        cost: getANumber(),
        weather: pickOneFromArray(weather),
        rate: random(0, 300) + '%',
        revenue: getANumber(-5000)
      }
    })
  }
}
</script>

<style scoped>

</style>
